<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Users"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.fullname" :rules="[validate_rules.required]" label="Name *"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.email" 
                      :disabled="isEdit"
                      :rules="[validate_rules.required, validate_rules.email]" 
                      label="Email *"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password"
                        label="Password *"
                        :rules="[validate_rules.password]"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="editedItem.user_role_id" 
                      :items="allUserRoles" 
                      item-value="id"
                      item-text="name"
                      label="Role">
                      </v-select>
                      <!-- <v-combobox
                        :items="allUserRoles"
                        label="Role"
                        item-value="id"
                        item-text="name"
                        v-model="editedItem.selected_userrole"
                        :return-object="true">
                      </v-combobox> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="5" sm="5">
              <v-text-field
                label="Search name,email ..."
                v-model="options.filter.keyword"
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
                clearable
              /> 
            </v-col>
            <v-col class="d-flex" cols="3" sm="3">
              <v-select
                :items="allUserRoles"
                label="Role"
                v-model="options.filter.role"
                no-data-text="Select.."
                item-value="id"
                item-text="name"
                dense
                clearable
                hide-details
              ></v-select>
            </v-col>
        </v-toolbar>
        <v-data-table 
            :items="filterList" 
            :headers="headers"
            sort-by="updated_at"
            :sort-desc="true"
            :loading="loadingDataTable"
        >
          <template #item.user_role_id="{value}">
              <span>{{ UserRolesArray[value] }}</span>
          </template>
          <template #item.actions="{item}">
                <v-btn 
                  v-if="canDo && (canDo.can.includes('detail') || canDo.can.includes('update'))"
                  class="ma-1" outlined color="primary" icon tile small @click="editItem(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn 
                  v-if="canDo && canDo.can.includes('delete')"
                  class="ma-1" outlined color="red" icon tile small @click="deleteItem(item)">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
          <template #item.updated_at="{value}">
              <span>{{moment(value).format('DD-MM-YYYY')}}</span>
          </template>
          
        </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'users',
  components: {
    pageHeading
  },
  data: () => ({
    dialog: false,
    options: {
        filter: {
          keyword: '',
          role: null,
        }
    },
    role_options : [{value: 1, text: 'Admin'}],
    showPassword : false,
    editedIndex: -1,
    editedItem: {
      id: -1,
      fullname: '',
      email: '',
      password: '',
      user_role_id: 1,
      selected_userrole: null
    },
    defaultItem: {
      id: -1,
      fullname: '',
      email: '',
      password: '',
      user_role_id: 1,
      selected_userrole: null
    },
    form_valid : false,
    validate_rules : {
      required: v => !!v || 'This field is required',
      min: v => v.length >= 8 || 'Min 8 characters',
      email: v => /.+@.+\..+/.test(v) || 'Valid email pattern is required',
      password: v => {
        if(v){
          return ( v.length >= 8 || 'Min 8 characters');
        } else {
          return true
        }
      }
    }
  }),
  computed: {
    ...mapGetters(['canDo','allUsers','allUserRoles','UserRolesArray','loadingDataTable']),
    headers(){
      var columns =  [
        {text: "ID", value: 'id'},
        {text: "Name", value: 'fullname'},
        {text: "Email", value: 'email'},
        {text: "Role", value: 'user_role_id'},
        {text: "Last Update", value: 'updated_at'},
        {text: "Actions", value: 'actions' , sortable: false},
      ];
      if(this.canDo && (this.canDo.can.includes('detail') || this.canDo.can.includes('update') || this.canDo.can.includes('delete'))){
        return columns;
      } else {
        return columns.filter(column => column.value != 'actions');
      }
    },
    isEdit () {
      return this.editedIndex > -1
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
    },
    filterList() { 
      var filter = this.options.filter;
      return _.filter(this.allUsers, function(query){
        var keyword = filter.keyword ? query.fullname.includes(filter.keyword) || query.email.includes(filter.keyword) : true,
            role = filter.role ? query.user_role_id == filter.role : true;
        return  keyword && role;
      })
    },
  },
  methods: {
    ...mapActions(['genCan','getUsers','updateUser','createUser','deleteUser']),

    addItem(){
      // this.$refs.form.resetValidation();
      this.dialog = true;
    },
    
    editItem (item) {
      this.editedIndex = this.allUsers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.password = ''
      this.dialog = true
    },

    deleteItem (item) {
        confirm('Are you sure you want to delete this user?') && this.deleteUser(item);
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.updateUser(this.editedItem);
        } else {
          this.createUser(this.editedItem);
        }
        this.close()
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  created() {
    this.genCan();
    this.getUsers();
  }
};
</script>